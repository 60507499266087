import { Injectable } from '@angular/core';
import { SegmentService } from './segment.service';
import { LocalCacheService } from './localCache.service';

@Injectable({ providedIn: 'root' })
export class ExperimentService {
  experimentVariant: string = 'ButtonColorA';
  experimentSwitch: boolean = true;
  constructor(
    private segmentService: SegmentService,
    private localCacheService: LocalCacheService,
  ) {}

  assignButtonColorVariant(name: string) {
    if (!this.experimentSwitch) return;
    const exp_buttonColor =
      this.localCacheService.getSessionStorage('exp_buttonColor');
    if (exp_buttonColor != null) {
      this.experimentVariant = exp_buttonColor;
    } else {
      this.experimentVariant =
        Math.random() < 0.5 ? 'ButtonColorA' : 'ButtonColorB';
      console.log('buttonExperiment', this.experimentVariant);
      this.localCacheService.saveSessionStorage(
        'exp_buttonColor',
        this.experimentVariant,
      );
    }
    const experimentValues = {
      eventName: 'ButtonColorExperiment',
      variant: this.experimentVariant,
      buttonName: name,
    };
    this.segmentService.segmentButtonColorExperiment(experimentValues);
  }
}
